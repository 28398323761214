import React, { useState, useEffect } from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { Link } from "react-router-dom";
import loaderimage from "../../images/giphy.gif";
const UsersList = () => {
  const [userList, setUserList] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);

  const headers: AxiosRequestHeaders = {
    "x-access-token": localStorage.getItem("jwttoken") || "",
  };
  const callApiUserList = async () => {
    try {
      setLoader(true)
      const url = process.env.REACT_APP_API_URL + "users/allusers";
      const response = await axios.get(url, { headers });
      //console.log(response);
      setUserList(response.data);
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    callApiUserList();
  }, []);

  const deleteUser = async (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to delete user?");
    if (val === true) {
      const url = process.env.REACT_APP_API_URL + "users/removeuser/" + id;
      const response = await axios.delete(url, { headers });
      //console.log(response);
      alert("User deleted");
    } else {
      alert("User not Deleted");
    }
    callApiUserList();
  };

  const activateUser = async (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to activate user?");
    if (val === true) {
      const url = process.env.REACT_APP_API_URL + "users/activateuser/" + id;
      let obj = {};
      const response = await axios.put(url, obj, { headers });
      //console.log(response);
      alert("User Activated");
    } else {
      alert("User not Activated");
    }
    callApiUserList();
  };

  const deactivateUser = async (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to activate user?");
    if (val === true) {
      //console.log(headers);
      const url = process.env.REACT_APP_API_URL + "users/deactivateuser/" + id;
      let obj = {};
      const response = await axios.put(url, obj, { headers });
      //console.log(response);
      alert("User DeActivated");
    } else {
      alert("User not DeActivated");
    }
    callApiUserList();
  };

  const sendMail = async (id:any) => {
    try {
      const url = process.env.REACT_APP_API_URL + "users/sendmail/" + id;
      const response = await axios.post(url, { headers });
      if (response.status === 200) {
        alert('Email sent successfully!');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };
  return (
    <div>
      <br></br>
      <Link to="/useraddadmin" className="btn btn-primary">
        Create New User
      </Link>
      <br></br>

      <table className="table table-striped">
        <thead >
          <tr className='Exheader'>
            <th>Sr No</th>
            <th className="bg-warning">UserName</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th className="bg-warning">Password</th>
            <th>Status</th>
            <th colSpan={4}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {userList &&
            userList.map((item, index) => (
              <tr key={index + item.userid}>
                <td>{index + 1}</td>
                <td className="bg-warning">{item.username}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.mobile}</td>
                <td className="bg-warning">{item.password}</td>
                <td className={item.status === 1 ? 'text-danger' : item.status === 2 ? 'text-success' : ''}>{item.status}</td>
                <td>
                  <Link
                    to={`/useredit/${item.userid}`}
                    className="btn btn-warning"
                  >
                    Edit
                  </Link>
                </td>
                <td>
                  <button
                    onClick={() => deleteUser(item.userid)}
                    className="btn btn-primary"
                  >
                    Delete User
                  </button>
                </td>
                <td>
                  {item.status === 1 &&  (
                    <button
                      onClick={() => activateUser(item.userid)}
                      className="btn btn-success"
                    >
                      Activate User
                    </button>
                  )}
                  {item.status === 2 && (
                    <button
                      onClick={() => deactivateUser(item.userid)}
                      className="btn btn-danger"
                    >
                      Deactivate User
                    </button>
                  )}
                </td>
                <td>
                  {item.status === 2 && (
                    <button
                      onClick={() => sendMail(item.userid)}
                      className="btn btn-info"
                    >
                      Send Mail
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <br></br>
      {loader && (
        <div className="loader">
          <img src={loaderimage} alt="Loader" />
        </div>
      )}
    </div>
  );
};

export default UsersList;
