
import React, { useState, useEffect } from 'react';
import axios,  { AxiosRequestHeaders }  from 'axios';
import loaderimage from "../../images/giphy.gif";
const ExamResults = () => {
  const [results, setResults] = useState([]);
  const [filterresults, setfilterResults] = useState([]);
  const [userIdInput, setUserIdInput] = useState('');
  const [examIdInput, setExamIdInput] = useState('');
  const [loader, setLoader] = useState(false);

  const headers : AxiosRequestHeaders = {
    "x-access-token": localStorage.getItem("jwttoken") || "",
  };

  const fetchExamResults = async () => {
    try {
      setLoader(true)
      const url = process.env.REACT_APP_API_URL + 'result/allresult';
      const response = await axios.get(url, { headers });
      setResults(response.data);
      setfilterResults(response.data);
    }
    catch (error) {
      console.log(error);
    }
    finally{
      setLoader(false)
    }
  }
  useEffect(() => {
    fetchExamResults();
  }, [])

  const handleFindById = (id) => {
    const foundResults = results.filter((item) => item.userid === userIdInput);
    // console.log(foundResults);
    setfilterResults([...foundResults]);
  };

  const handleFindByExamId = (id) => {
    const foundResults = results.filter((item) => item.exam_id + '' === examIdInput);
    // console.log(foundResults);
    setfilterResults([...foundResults]);
  };

  return (
    <div>
      <>
        <div className='row'>
          <div className='col-6'>
            <button className='btn btn-success' onClick={fetchExamResults}>Refresh</button>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <label>Enter User ID:
              <input
                type="text"
                value={userIdInput}
                onChange={(e) => setUserIdInput(e.target.value)}
              /> </label>
            <button className="btn btn-primary" onClick={handleFindById} >
              Find by User ID
            </button>
          </div>
          <div className='col-6'>
            <label>Enter Exam ID:
              <input
                type="text"
                value={examIdInput}
                onChange={(e) => setExamIdInput(e.target.value)}
              /></label>
            <button className="btn btn-primary" onClick={() => handleFindByExamId('exam_id')}>
              Find by Exam ID
            </button>
          </div>
        </div>


        <table className='table table-striped'>
          <thead>
            <tr className='Exheader'>
              <th>Sr No</th>
              <th>Exam Taken ID</th>
              <th>Date</th>
              <th>Time</th>
              <th>Exam ID</th>
              <th>User ID</th>
              <th className='bg-warning'>Name</th>
              <th>Total Marks</th>
              <th className='bg-warning'>Final Marks</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Total QS</th>
              <th>Attempted QS</th>
              <th>Correct QS</th>
              <th>InCorrect QS</th>
              <th>Positive Marks</th>
              <th>Nagative Marks</th>

            </tr>
          </thead>
          <tbody>
            {
              filterresults && filterresults.map((item, index) => {
                let positivemarking = item.positive_marks / item.correct_qs;
                let total_marks = item.total_qs * positivemarking
                let final_obtained_marks = item.positive_marks - item.negative_marks
                return (
                  <tr key={index + item.userid}>
                    <td>{index + 1}</td>
                    <td>{item.exam_taken_id}</td>
                    <td>{item.date.substring(0,10)}</td>
                    <td>{item.date.substring(11,16)}</td>
                    <td>{item.exam_id}</td>
                    <td>{item.userid}</td>
                    <td className='bg-warning'>{item.name}</td>
                    <td>{total_marks}</td>
                    <td className='bg-warning'>{final_obtained_marks}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{item.total_qs}</td>
                    <td>{item.attempted_qs}</td>
                    <td>{item.correct_qs}</td>
                    <td>{item.incorrect_qs}</td>
                    <td>{item.positive_marks}</td>
                    <td>{item.negative_marks}</td>
                  </tr>
                )
              }
              )
            }
          </tbody>
        </table>
        <br></br>
      </>
      {loader && (
        <div className="loader">
          <img src={loaderimage} alt="Loader" />
        </div>
      )}
    </div>
  )
}
export default ExamResults
