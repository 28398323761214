import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import WebCameComponent from './WebCameComponent';
import loaderimage from "../../images/giphy.gif";
const ExamDetail = () => {
  const { examid } = useParams();
  const [loader, setLoader] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate(); 
  const handleDeclarationChange = () => {
    setIsDeclarationChecked(!isDeclarationChecked);
  };
  //console.log(examid);

  const [examList, setExamList] = useState({});
  const userId = window.localStorage.getItem('userid');
  const headers = {
    "x-access-token": localStorage.getItem("jwttoken") || "",
  };

  const callApiQsList = async () => {
    try {
      setLoader(true)
      const url = process.env.REACT_APP_API_URL + 'exams/fetchexam/' + examid;
      const response = await axios.get(url,{headers});
      //console.log(response.data[0]);
      setExamList(response.data[0]);
    }
    catch (error) {
      console.log(error);
    }
    finally{
      setLoader(false)
    }
  }

  useEffect(() => {
    callApiQsList();
  }, [])

  useEffect(() => {
    window.localStorage.setItem('examname', examList.examname);
    window.localStorage.setItem('positivemarking', examList.marksCorrect);
  }, [examList])

  useEffect(() => {
    //console.log('examList', examList);
    requestCameraAccess();
  },[])

  const [cameraPermission, setCameraPermission] = useState(false);
  const requestCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermission(true);
    } catch (err) {
      console.error("Camera permission denied", err);
      setCameraPermission(false);
      // setTimeout(requestCameraAccess, 3000); 
    }
  };
  const handleProceedClick = () => {
    if (!isDeclarationChecked || !cameraPermission) {
      setIsModalVisible(true);
    } else {
      navigate(`/examprogress/${examid}`);
    }
  };
  return (
    <div className='container no-select'>
      {/* <div className='row'>
        <div className='col-12 text-center'>
          <h2>{examList.examname}</h2>
          <br></br>
        </div>
      </div> */}
      {loader && (
        <div className="loader">
          <img src={loaderimage} alt="Loader" />
        </div>
      )}
      <div className="row">
  <div className="col-md-12">
    <div className="card shadow-sm mb-4">
      <div className="card-body">
        <div className="row align-items-center">
          {/* Exam Name Section */}
          <div className="col-md-6">
            <h5 className="fw-bold text-primary mb-0">
              Exam Name: <span className="text-dark">{examList.examname}</span>
            </h5>
          </div>

          {/* Exam Duration and Webcam Section */}
          <div className="col-md-6">
            <div className="d-flex justify-content-end align-items-center">
              <h5 className="fw-bold text-primary mb-0 me-3">
                Exam Duration: <span className="text-dark">{examList.duration} minutes</span>
              </h5>
              <div className="border rounded p-2" style={{ width: '100px', height: '100px' }}>
                {cameraPermission?<WebCameComponent />:<p className=' ' style={{ fontSize: '8px' }} >To continue, we kindly request access to your camera.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <div className='row'>
      <div className="col-12 mb-4">
  <div className="card shadow-sm">
    <div className="card-body">
      <h5 className="fw-bold text-dark mb-3" style={{ marginTop: '5px' }}>
        Read the following instructions carefully:
      </h5>
      {examList && (
        <ol className="ms-4" style={{ listStyleType: 'decimal' }}>
          <li>Make sure you have a good internet connection.</li>
          <li>Do not leave the meeting before submitting the exam.</li>
          <li>Screen share and Camera are compulsory.</li>
          <li>Avoid noise or any discussion during the exam.</li>
          <li>Read all questions carefully before answering.</li>
          <li>Total number of questions: {examList.numOfQue}.</li>
          <li>All questions in this quiz are multiple-answer type. Each question has 1 correct answer out of 4 choices.</li>
          <li>You will be awarded {examList.marksCorrect} for each correct answer, and {examList.marksIncorrect} will be deducted for each wrong answer.</li>
          <li>There is no negative marking for unattempted questions.</li>
          <li>Complete the exam within the specified duration.</li>
          <li>You can take this test only once. Complete the test before submitting or closing the browser.</li>
          <li>Do not refresh the page or right-click during the exam. Your exam data will be lost.</li>
          <li>If your exam closes unexpectedly, you can restart from the same point.</li>
          <li>Answers will be automatically saved when submitted.</li>
          <li>Answer submission: Click on the correct answer and then click the Submit Answer button.</li>
        </ol>
      )}
    </div>
  </div>
</div>

        <div className='row'>
          <div className='col-12' style={{ border: '1px solid #ccc', padding: '8px', display: 'flex', flexDirection: 'column' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                           <label htmlFor='languageSelect' style={{ marginRight: '10px' , marginBottom: '10px'}}>Choose your default Language:</label>
                           <select className='form-control' id='languageSelect' style={{ width: 'auto', marginBottom: '10px' }}>
                             <option value='english'>English</option>
                             <option value='spanish'>Hindi</option>
                           </select>
                       </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className='form-check'>
                <input type='checkbox' className='form-check-input' id='declarationCheck' checked={isDeclarationChecked}
                  onChange={handleDeclarationChange} />
                <label className='form-check-label' htmlFor='declarationCheck'>I declare that I have read all the instructions carefully and understood them. I agree not to cheat or use unfair means in this examination.</label>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="text-primary">Click "Proceed to Start Exam" to beign</p> */}
        <div className=' col-12 d-flex justify-content-center align-items-center'>

          {isDeclarationChecked && cameraPermission? (
            <div>
              <button className="btn btn-primary" onClick={handleProceedClick} >Proceed to Start Exam</button>
            </div>
          ) : (
            <button className="btn btn-secondary" onClick={() => setIsModalVisible(true)}>
              Proceed to Start Exam</button>
          )}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>

      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Confirmation Alert</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>{cameraPermission
            ? 'Please tick/check the declaration before proceeding.'
            : 'Camera permission is required. Please allow camera access to proceed.'}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => {
    setIsModalVisible(false);
    requestCameraAccess();
  }} >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ExamDetail