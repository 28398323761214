import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import loadImage from "../../images/giphy.gif";
import io from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css';

const WebCameComponent = () => {
  const [cameraPermission, setCameraPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const webcamRef = useRef(null);
  const socket = useRef(null);
  const userid =localStorage.getItem('userid')
  const url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks()[0].onended = handleStreamEnded;
        if (stream) {
          setCameraPermission(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Camera access denied or unavailable:', error);
        setCameraPermission(false);
        setIsLoading(false);
      }
    };

    checkCameraPermission();
    socket.current = io(url);

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  const handleStreamEnded = () => {
    window.location.reload();
  };

  const captureFrame = () => {
    if (webcamRef.current && webcamRef.current.getScreenshot) {
      const screenshot = webcamRef.current.getScreenshot();
      if (socket.current) {
        socket.current.emit('exam_stream', {
          type: 'exam_stream',
          data: screenshot,
          userid: userid
        });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(captureFrame, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {!cameraPermission ? (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content bg-transparent border-0">
              <div className="modal-body d-flex justify-content-center align-items-center vh-100">
                {isLoading ? (
                  <>
                    <img src={loadImage} alt="Loading" className="loading-image" />
                    <p className="loading-text">Loading</p>
                  </>
                ) : (
                  <div className="card p-4 text-center">
                    <div className="card-body">
                      <h5 className="card-title">Camera Access Required</h5>
                      <p className="camera-access-text card-text">Please grant camera access to continue.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: 80,
            height: 80,
            facingMode: 'user',
          }}
        />
      )}
    </div>
  );
};

export default WebCameComponent;