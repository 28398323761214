import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css';

const MonitorStudents = () => {
    const [streams, setStreams] = useState({});
    const streamRefs = useRef({});  // To store references to each stream's image element
    const url = process.env.REACT_APP_API_URL;
    const socket = io(url);

    useEffect(() => {
        socket.on('new_stream', (data) => {
            setStreams((prevStreams) => ({
                ...prevStreams,
                [data.userid]: data.data,
            }));
        });

        return () => {
            socket.disconnect();
        };
    }, [socket]);

    // Function to request fullscreen for the given student feed
    const handleFullscreen = (userid) => {
        const imgElement = streamRefs.current[userid];
        if (imgElement.requestFullscreen) {
            imgElement.requestFullscreen();
        } else if (imgElement.webkitRequestFullscreen) { /* Safari */
            imgElement.webkitRequestFullscreen();
        } else if (imgElement.msRequestFullscreen) { /* IE11 */
            imgElement.msRequestFullscreen();
        }
    };

    const renderStreams = () => {
        return Object.keys(streams).map((userid) => (
            <div key={userid} className="col-md-4 col-sm-6 mb-4">
                <div className="card shadow-sm h-100">
                    <div className="card-header text-white bg-primary">
                        <h5 className="card-title mb-0">Student {userid}</h5>
                    </div>
                    <div className="card-body d-flex justify-content-center">
                        <img
                            ref={(el) => (streamRefs.current[userid] = el)}  // Save the reference to the image element
                            src={streams[userid]}
                            alt={`Stream from ${userid}`}
                            className="img-fluid rounded"
                            style={{ maxHeight: '200px', cursor: 'pointer' }} // Clickable cursor
                        />
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                        <span className="badge bg-success">Live</span>
                        <button 
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => handleFullscreen(userid)}  // Handle fullscreen click
                        >
                            View Fullscreen
                        </button>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="container">
            <h2 className="my-4 text-center">Live Feed of All Students</h2>
            <div className="row">
                {Object.keys(streams).length > 0 ? (
                    renderStreams()
                ) : (
                    <div className="col-12">
                        <div className="alert alert-warning text-center" role="alert">
                            No active student streams available.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MonitorStudents;