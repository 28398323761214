import React, { useState, useEffect } from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { Link } from "react-router-dom";
import loaderimage from "../../images/giphy.gif";
const CurrentExamineesList = () => {
  const [examineesList, setExaminees] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);

  const headers: AxiosRequestHeaders = {
    "x-access-token": localStorage.getItem("jwttoken") || "",
  };
  const callApiExamineesList = async () => {
    try {
      setLoader(true)
      const url = process.env.REACT_APP_API_URL + "exams/current_examinees";
      const response = await axios.get(url, { headers });
      //console.log(response);
      setExaminees(response.data);
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoader(false)
    }
  };
  const updateHoldStatus = async (id: any,uid:any,status:any) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to update status?");
    if (val === true) {
      try {
        let url = "";
  
        if (status == 1) {
          url = process.env.REACT_APP_API_URL + "exams/markhold/" + id +'/'+uid;
        } else if (status == 0) {
          url = process.env.REACT_APP_API_URL + "exams/markresume/" + id+'/'+uid;
        }
        if (url) {
          const response = await axios.put(url, {},{ headers });
          alert("User status updated successfully.")
        }
      } catch (error) {
        console.error("Error updating user status:", error);
        alert("Failed to update user status.");
      }
    } else {
      alert("User status not updated !!");
    }
    callApiExamineesList();
  };
  useEffect(() => {
    callApiExamineesList();
  }, []);

  return (
    <div>
      <table className="table table-striped">
        <thead >
          <tr className='Exheader'>
            <th>Sr No</th>
            <th className="">UserID</th>
            <th className="">UserName</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>ExamID</th>
            <th colSpan={3}>Action</th>
          </tr>
        </thead>
        <tbody>
          {examineesList &&
            examineesList.map((item, index) => (
              <tr key={item.userid}>
                <td>{index + 1}</td>
                <td className="">{item.userid}</td>
                <td className="">{item.username}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.mobile}</td>
                <td>{item.exam_id}</td>
                <td>
                  {item.hold_status==0?<button
                    onClick={() => updateHoldStatus(item.userid,item.exam_id,1)}
                    className="btn btn-danger"
                  >
                    Hold
                  </button>:<button
                    onClick={() => updateHoldStatus(item.userid,item.exam_id,0)}
                    className="btn btn-success"
                  >
                    Resume
                  </button>}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <br></br>
      {loader && (
        <div className="loader">
          <img src={loaderimage} alt="Loader" />
        </div>
      )}
    </div>
  )
}

export default CurrentExamineesList
